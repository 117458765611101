import EdgeScroll from 'o365.modules.utils.EdgeScroller.ts';

// const listenerStore: Record<string, {
//     listener: (pEvent: MouseEvent) => void,
//     intervalId?: number,
//     timer: null | number
// }> = {};

const scrollStates = new Map<string, EdgeScroll>();

function getState(pId: string, pOptions?: Required<EdgeScrollOptions>) {
    if (!scrollStates.has(pId) && pOptions) {
        scrollStates.set(pId, new EdgeScroll(pOptions));
    }
    return scrollStates.get(pId);
}

// function getState(pId: string, pOptions: Required<EdgeScrollOptions>) {
//     if (listenerStore[pId] == null) {
//         listenerStore[pId] = {
//             timer: null,
//             listener: (e: MouseEvent) => {
//                 return;
//                 const scrollSpeed = pOptions.scrollSpeed;
//                 const scrollThreshold = pOptions.scrollThreshold;

//                 const rect = pOptions.containerEl.getBoundingClientRect();
//                 const mouseX = e.clientX - rect.left;
//                 const mouseY = e.clientY - rect.top;
//                 console.log(mouseX, mouseY)

//                 let scrollX = 0;
//                 let scrollY = 0;
//                 if (!pOptions.disableX) {
//                     if (mouseX > rect.width - scrollThreshold) {
//                         scrollX = scrollSpeed;
//                     }
//                     else if (mouseX < scrollThreshold) {
//                         scrollX = -scrollSpeed;
//                     }
//                 }

//                 if (!pOptions.disableY) {
//                     if (mouseY > rect.height - scrollThreshold) {
//                         scrollY = scrollSpeed;
//                     }
//                     else if (mouseY < scrollThreshold) {
//                         scrollY = -scrollSpeed;
//                     }
//                 }

//                 if (scrollX === 0 && scrollY === 0 && listenerStore[pId]?.timer) {
//                     window.clearTimeout(listenerStore[pId].timer!);
//                 }
//                 if (scrollX === 0 && scrollY === 0) {
//                     console.log('return here')
//                     return;
//                 }
//                 if (listenerStore[pId].timer == null) {
//                     console.log('scroll by', scrollX, scrollY)
//                     pOptions.scrollEl.scrollBy(scrollX, scrollY);
//                     listenerStore[pId].timer = setTimeout(() => {
//                         if (listenerStore[pId]) {
//                             listenerStore[pId].timer = null;
//                             listenerStore[pId].listener(e);
//                         }
//                     }, 30);
//                 }
//                 console.log('event end')
//             }
//         };
//     }
//     return listenerStore[pId];

// }

function getDefaultOptions(pElement: HTMLElement) {
    return {
        scrollAxis: 'y' as 'y',
        scrollContainer: pElement,
    };
}

type EdgeScrollOptions = {
    enabled: boolean,
    // scrollThreshold?: number,
    // scrollSpeed?: number,
    // disableX?: boolean,
    // disableY?: boolean,
    scrollAxis: 'x' | 'y' | 'xy'
    scrollContainer?: HTMLElement,
    // scrollEl?: HTMLElement
};

type EdgeScrollDirectiveBinding = { value: boolean | EdgeScrollOptions };

/**
 * Directive for scrolling a container when the mouse approaches it's edge.
 * @binding boolean value when true will scroll the container whenever the mouse approaches the edge.
 * Advanced options object is also accepted, see {EdgeScrollOptions}
 * @example <div class="overflow-auto" v-edge-scroll="isEnabled"></div>
 */
const vEdgeScroll = {
    mounted(el: HTMLElement, binding: EdgeScrollDirectiveBinding) {
        const options: Required<EdgeScrollOptions> = typeof binding.value === 'boolean'
            ? { ...getDefaultOptions(el), enabled: binding.value }
            : { ...getDefaultOptions(el), ...binding.value };
        const id = crypto.randomUUID();
        el.dataset.oEdgeScroll = id;
        if (options.enabled) {
            const state = getState(id, options);
            state!.enable();
        }
    },
    updated(el: HTMLElement, binding: EdgeScrollDirectiveBinding) {
        const id = el.dataset.oEdgeScroll;
        if (id) {
            const options: Required<EdgeScrollOptions> = typeof binding.value === 'boolean'
                ? { ...getDefaultOptions(el), enabled: binding.value }
                : { ...getDefaultOptions(el), ...binding.value };
            if (options.enabled && !scrollStates.has(id)) {
                const state = getState(id, options);
                state!.enable();
            } else if (options.enabled && scrollStates.has(id)) {
                const state = getState(id, options);
                state!.enable();
            }
        }
    },
    beforeUnmount(el: HTMLElement) {
        const id = el.dataset.oEdgeScroll;
        if (id) {
            if (scrollStates.has(id)) {
                const state = getState(id);
                state!.disable();
                scrollStates.delete(id);
            }
        }
    },
};

export default vEdgeScroll;